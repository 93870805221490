var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "gallery2-items"
  }, _vm._l(_vm.boards, function (board) {
    return _c('div', {
      key: board._id,
      staticClass: "gallery2-item",
      on: {
        "click": function ($event) {
          return _vm.$emit('click', board);
        }
      }
    }, [_c('span', {
      staticClass: "gallery2-item__inner"
    }, [_c('div', {
      staticClass: "gallery2-item__img",
      style: {
        backgroundImage: `url('${board.thumb}')`
      }
    }), _c('div', {
      staticClass: "gallery2-item__con"
    }, [_c('h3', {
      staticClass: "gallery2-item__tit"
    }, [_vm._v(_vm._s(board.subject))]), _c('div', {
      staticClass: "gallery2-item__txt"
    }, [_vm._v(_vm._s(board.summary))])])])]);
  }), 0), _c('div', {
    staticClass: "board-buttons board-buttons--center"
  }, [!_vm.hideWriteButton ? _vm._t("write-button") : _vm._e()], 2), !_vm.hidePagination ? _vm._t("pagination") : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }