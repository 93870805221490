<template>
<div>
    <div class="board-container">
        <div class="gallery2-items">
            <div v-for="board in boards" :key="board._id" class="gallery2-item" @click="$emit('click', board)">
                <span class="gallery2-item__inner">
                    <div class="gallery2-item__img" :style="{ backgroundImage: `url('${board.thumb}')`}"></div>
                    <div class="gallery2-item__con">
                        <h3 class="gallery2-item__tit">{{ board.subject }}</h3>
                        <div class="gallery2-item__txt">{{ board.summary }}</div>
                    </div>
                </span>
            </div>
        </div>

        <div class="board-buttons board-buttons--center">
            <slot v-if="!hideWriteButton" name="write-button"></slot>
        </div>

        <slot v-if="!hidePagination" name="pagination"></slot>
    </div>
</div>
</template>

<script>
export default {
    props: {
        summary: { type: Object, default () { return { totalCount: 0 }} },
        boards: { type: Array, default: null },

        hideWriteButton: { type: Boolean, default: false },
        hidePagination: { type: Boolean, default: false }
    },
}
</script>
